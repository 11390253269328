
import ShowRelBtn from "@/components/showRelBtn.vue";

import LogHistory from "@/views/shared/logHistory.vue";
import Pager from "@/views/layout/pager.vue";
import { ElMessageBox } from "element-plus";
import roles from "@/types/roles.ts";
// import Attach from "./attach.vue";

import { useCityRelTown } from "@/network/chinaArea";
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
  watch,
} from "vue";
import {
  useQueryPermissionPlan,
  useSetupProjectAction,
  useQueryFileSignManageActionHistory,
} from "@/network/setupProject";

import {
  dicLabel,
  useSelectItemList,
  useSelectItemWithChildList,
  scheduleTypeDic,
  permissionPlanStatusText,
} from "@/network/lookUp.ts";
import Base from "@/views/layout/Base.vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  props: {
    setupGroup: {
      type: Number,
      default: 1
    }
  },
  components: {
    Base,
    Pager,
    //LogHistory,
    // ShowRelBtn
    //, Attach
  },
  setup(props) {

    const $reload = inject<any>("$reload");
    watch(() => props.setupGroup, () => {
      $reload();
    })


    const user: any = inject("user");
    const activeName = ref(null);
    const router = useRouter();
    const route = useRoute();


    const filter = reactive({
      yearBatch: null,
      pageNum: 1,
      pageSize: 20,
      status: null,
      keyword: null,
      city: null,
      town: null,
      projectType: null,
      heritageUnitClass: null,
      isRevolution: null,
      isSafeProject: null,
      setupGroup: props.setupGroup
    });




    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town"),
      null
    );

    const dicHeritageClass = useSelectItemList("HeritageClass");
    const manage = (id) => {
      router.push({ name: `PermissionPlanManage`, params: { id } });
    };
    const [isLoading, pagerData, query, pager] = useQueryPermissionPlan(filter);

    const [
      isActionLoading,
      ,
      removeFn
    ] = useSetupProjectAction();


    const remove = (id) => {
      removeFn(id).then(() => {
        query();
      });
    };



    const showLogHistory = ref(false);
    const [logs, queryHistory] = useQueryFileSignManageActionHistory();

    const doQueryHistory = (id) => {
      showLogHistory.value = true;
      queryHistory(id);
    };


    onMounted(() => {
      if (user.inRoles(roles.city)) {
        filter.status = 1;
        activeName.value = "1";
        filter.city = user.city_ID;
        //console.log(user.city_ID);
        // const stop = watch(dicCity, () => {
        //   filter.city = user.city_ID;
        //   stop();
        // });
      } else if (user.inRoles(roles.prov)) {
        activeName.value = "2";
        filter.status = 2;
      } else {
        filter.status = 0;
        activeName.value = "0";
      }
      if (route.query.tab) {
        activeName.value = route.query.tab;
        let s = parseInt(activeName.value)
        if (s)
          filter.status = s
      }

      query();
    });


    return {
      showLogHistory,
      doQueryHistory,
      logs,



      pager,
      remove,

      permissionPlanStatusText,
      scheduleTypeDic,
      dicHeritageClass,
      activeName,

      dicCity,
      dicTown,

      dicLabel,
      isLoading,
      isActionLoading,
      user,
      roles,
      manage,
      query,
      pagerData,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
